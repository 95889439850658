import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  VerticalArrowLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalArrowLine.skin' /* webpackChunkName: "VerticalLine_VerticalArrowLine" */
    ),
  ],
  VerticalArrowLineTop: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalArrowLineTop.skin' /* webpackChunkName: "VerticalLine_VerticalArrowLineTop" */
    ),
  ],
  VerticalDashedLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalDashedLine.skin' /* webpackChunkName: "VerticalLine_VerticalDashedLine" */
    ),
  ],
  VerticalDottedLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalDottedLine.skin' /* webpackChunkName: "VerticalLine_VerticalDottedLine" */
    ),
  ],
  VerticalDoubleLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalDoubleLine.skin' /* webpackChunkName: "VerticalLine_VerticalDoubleLine" */
    ),
  ],
  VerticalFadeNotchLeftLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalFadeNotchLeftLine.skin' /* webpackChunkName: "VerticalLine_VerticalFadeNotchLeftLine" */
    ),
  ],
  VerticalFadeNotchRightLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalFadeNotchRightLine.skin' /* webpackChunkName: "VerticalLine_VerticalFadeNotchRightLine" */
    ),
  ],
  VerticalIronLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalIronLine.skin' /* webpackChunkName: "VerticalLine_VerticalIronLine" */
    ),
  ],
  VerticalNotchDashedLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalNotchDashedLine.skin' /* webpackChunkName: "VerticalLine_VerticalNotchDashedLine" */
    ),
  ],
  VerticalNotchLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalNotchLine.skin' /* webpackChunkName: "VerticalLine_VerticalNotchLine" */
    ),
  ],
  VerticalShadowLeftLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalShadowLeftLine.skin' /* webpackChunkName: "VerticalLine_VerticalShadowLeftLine" */
    ),
  ],
  VerticalShadowRightLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalShadowRightLine.skin' /* webpackChunkName: "VerticalLine_VerticalShadowRightLine" */
    ),
  ],
  VerticalSloopyLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalSloopyLine.skin' /* webpackChunkName: "VerticalLine_VerticalSloopyLine" */
    ),
  ],
  VerticalSolidLine: () => [
    import(
      './viewer/skinComps/BaseVerticalLine/VerticalSolidLine.skin' /* webpackChunkName: "VerticalLine_VerticalSolidLine" */
    ),
  ],
  VerticalLineSkin: () => [
    import(
      './viewer/skinComps/VerticalLineSkin/VerticalLineSkin.skin' /* webpackChunkName: "VerticalLine_VerticalLineSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('VerticalLine', UiTypes),
};

export default entry;
