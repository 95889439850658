import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/Accordion' /* webpackChunkName: "Accordion" */),
    import(
      '../TPAGallery/viewer/TPAGallery.controller' /* webpackChunkName: "Accordion" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('Accordion', UiTypes),
};

export default entry;
