import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import('./viewer/Masonry' /* webpackChunkName: "Masonry" */),
    import(
      '../TPAGallery/viewer/TPAGallery.controller' /* webpackChunkName: "Masonry" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('Masonry', UiTypes),
};

export default entry;
